import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Flex, Spinner } from '@chakra-ui/react';
import {
  USER_STATUS,
  USER_ROLES_IDS,
  SUBSCRIPTION_STATUS,
} from '@/utils/constants/user';

import MainLayout from '@/layouts/MainLayout';
import { useAuthorization } from '@/utils/Auth/use-authorization';
import { pages } from '@/utils/pages';

const RootPage = () => {
  const { session, profile } = useAuthorization();
  const router = useRouter();

  useEffect(() => {
    const userStatus = profile.data?.status;

    if (
      [USER_STATUS.invited, USER_STATUS.signupCompleted].includes(userStatus) ||
      profile.data?.subscriptionStatus === SUBSCRIPTION_STATUS.canceled
    ) {
      router.push(pages.registration);
    } else if (profile.data?.status === USER_STATUS.onboardingCompleted) {
      if (profile.data.role.id === USER_ROLES_IDS.artistManager) {
        router.push(pages.artistsOverview);
      } else {
        router.push(pages.admin);
      }
    }
  }, [profile.data, router, session]);

  return (
    <MainLayout>
      <Flex w='full' h='full' alignItems='center' justifyContent='center'>
        <Spinner color='alpha.500' size='lg' />
      </Flex>
    </MainLayout>
  );
};

export default RootPage;
